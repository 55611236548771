import React from "react"
import { Box, Link } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import { LinkItemProps } from "../../types/LinkItemProps"

type Props = {
  links: LinkItemProps[]
}

export const LinkList: React.FC<Props> = ({ links }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        "& img": {
          transition: "filter 300ms !important",
          filter: "brightness(0) saturate(100%)",
        },
      }}
    >
      {links.map(link => (
        <Link
          key={link.link}
          href={link.link}
          target="_blank"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: { xs: 1, md: 2 },
            "&:hover": {
              "& img": {
                filter: "none",
              },
            },
          }}
        >
          {link.thumbnail ? <GatsbyImage image={link.thumbnail} alt={link.alt} /> : link.alt}
        </Link>
      ))}
    </Box>
  )
}
