import React from "react"
import { Button } from "@mui/material"

export const SpotifyIframe: React.FC<{ type: string; id: string }> = ({ type, id }) => {
  return (
    <>
      <iframe
        title="spotify-playlist"
        style={{ borderRadius: "12px" }}
        data-consent="marketing"
        data-src={`https://open.spotify.com/embed/${type}/${id}`}
        width="100%"
        height="380"
        frameBorder="0"
        allowFullScreen={false}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
      <Button sx={{ marginBottom: 2 }} size="small" variant="contained" href={`https://open.spotify.com/${type}/${id}`}>
        Open on Spotify
      </Button>
    </>
  )
}
