import React from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { ListItem } from "."

type Props = {
  panelId: string
  title: string
  children: React.ReactNode
}
export const FoldableListItem: React.FC<Props> = ({ children, title, panelId }) => {
  return (
    <ListItem>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={panelId} id={panelId}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </ListItem>
  )
}
