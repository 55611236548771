import { Box, styled } from "@mui/material"

export const ListItem = styled(Box, {
  shouldForwardProp: prop => prop !== "color",
})(({ theme, color }) => ({
  minHeight: "74px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  border: "1px solid #000",
  transition: "border 300ms, color 300ms",
  color: "inherit",
  textDecoration: "none",

  "& img": {
    transition: "filter 300ms !important",
    filter: "brightness(0) saturate(100%)",
  },

  "&:hover": {
    border: `1px solid ${color ? color : theme.palette.secondary.dark}`,
    color: color ? color : theme.palette.secondary.dark,
  },
}))
