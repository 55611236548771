import * as React from "react"
import { Typography } from "@mui/material"
import { graphql, Link, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Layout } from "../components/Layout"
import { LinkList } from "../components/LinkList"
import { ListItem } from "../components/ListItem"
import { FoldableListItem } from "../components/ListItem/FoldableListItem"
import { SEO } from "../components/Seo"
import { SpotifyIframe } from "../components/SpotifyIframe"
import { LinkItemProps } from "../types/LinkItemProps"

type DataProps = {
  image: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  socialIcons: {
    nodes: {
      id: string
      name: string
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }[]
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const links: LinkItemProps[] = [
    { icon: "instagram", link: "https://instagram.com/paulie.v.beats/", alt: "paulie.v Instagram" },
    {
      icon: "spotify",
      link: "https://open.spotify.com/artist/1V1QGgrFJ2TIRYzJE1z9Yl",
      alt: "paulie.v Spotify artist page",
    },
    {
      icon: "apple-music",
      link: "https://music.apple.com/us/artist/paulie-v/1643498525",
      alt: "paulie.v Apple Music artist page",
    },
    {
      icon: "youtube",
      link: "https://www.youtube.com/channel/UCvNryo4eOu7LgFo6SNCXd6w",
      alt: "paulie.v Youtube channel",
    },
  ].map((link: LinkItemProps) => {
    link.thumbnail = data.socialIcons.nodes.find(icon => icon.name === link.icon)?.childImageSharp.gatsbyImageData
    return link
  })

  return (
    <Layout title="paulie.v" image={data.image.childImageSharp.gatsbyImageData}>
      <LinkList links={links} />
      <FoldableListItem title="hamburg sessions" panelId="hamburg-sessions">
        <SpotifyIframe type="album" id="3J5vcSVXrUVdjE1qs9Mf9D" />
      </FoldableListItem>
      <FoldableListItem title="Soothing beats - Playlist" panelId="soothing-beats">
        <SpotifyIframe type="playlist" id="1xbZNfdksmWks6qNmhsT1S" />
      </FoldableListItem>
      <ListItem component={Link} to="/about/">
        <Typography>About me</Typography>
      </ListItem>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    image: file(relativePath: { eq: "pauliev.jpeg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, height: 128, width: 128)
      }
    }
    socialIcons: allFile(filter: { relativeDirectory: { eq: "social" } }) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            height: 40
            width: 40
            transformOptions: { fit: INSIDE }
            placeholder: TRACED_SVG
          )
        }
      }
    }
  }
`

export const Head = () => <SEO title="paulie.v official website" />
